export interface Transaction {
  transactionId: string;
  companyUuid: string;
  userUuid: string;
  deviceUuid: string;
  deviceSerialNumber: string;
  connectorId: number;
  deviceName: string;
  carUuid?: string;
  carBrand?: string;
  carModel?: string;
  carName?: string;
  parkingCost?: number;
  reservationCost?: number;
  siteUuid: string;
  siteName: string;
  startTime: Date;
  endTime: Date;
  status?: TransactionStatus;
  startAmount: number;
  amount: number;
  monetaryCost?: number;
  monetaryCostWithVAT?: number;
  monetaryRevenue?: number;
  monetaryRevenueWithVAT?: number;
  addTime: Date;
  updateTime: Date;
  invoiceId?: string;
  publicChargingFlag: boolean;
  currency?: string;
}

export enum TransactionStatus {
  Started = 'Started',
  Ended = 'Ended',
  Updated = 'Updated',
  Pending = 'Pending',
  CanceledByUser = 'CanceledByUser',
}
