import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createEvses, isEvseAvailable } from 'handlers/device/device.handler';
import { partition } from 'lodash';
import { Evse } from 'models/evse';
import { Site } from 'models/site';
import { selectUser } from 'services/auth/selectors';
import { selectActiveReservation } from 'services/parkingAndReservations/selectors';
import { setActiveEvse } from 'services/sites';
import { selectActiveDevice } from 'services/sites/selectors';
import { selectActiveTransaction } from 'services/transactions/selectors';

import { TryNowBox } from '../Login/TryNowBox';
import { ChargersListing } from './ChargerListing';

type SiteCharagersListProps = {
  site: Site;
};

export const SiteCharagersList = ({ site }: SiteCharagersListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const activeTransaction = useSelector(selectActiveTransaction);
  const activeReservation = useSelector(selectActiveReservation);
  const activeDevice = useSelector(selectActiveDevice);

  const evses = site.devices
    .filter((device) => device.uuid === activeDevice?.uuid || !activeDevice)
    .flatMap((device) => createEvses(device));
  const [availableDevices, unavailableDevices] = partition(evses, (d) =>
    isEvseAvailable(d, activeTransaction, activeReservation),
  );

  const setActiveEvseIfAvailable = (evse: Evse) => {
    const available = isEvseAvailable(evse, activeTransaction, activeReservation);
    if (available) {
      dispatch(setActiveEvse(evse));
    }
  };

  return (
    <div className="pt-1 pb-40">
      {!user && <TryNowBox />}
      <ChargersListing
        title={t('available', 'Available')}
        evseList={availableDevices}
        site={site}
        setActiveEvseIfAvailable={setActiveEvseIfAvailable}
      />
      <ChargersListing
        title={t('unavailable', 'Unavailable')}
        evseList={unavailableDevices}
        site={site}
        setActiveEvseIfAvailable={setActiveEvseIfAvailable}
      />
    </div>
  );
};
