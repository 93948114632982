import { splitApi } from 'services/api';

import { SiteHourlyPricesResponse, SitesResponse } from './responses';

const extendedApi = splitApi.injectEndpoints({
  endpoints: (builder) => ({
    publicSites: builder.query<SitesResponse, undefined>({
      query: () => ({
        url: '/sites/user',
        method: 'GET',
      }),
    }),
    getSiteHourlyPrices: builder.query<SiteHourlyPricesResponse, string>({
      query: (uuid) => ({
        url: `/sites/${uuid}/hourlyPrices?withVat=true`,
        method: 'GET',
      }),
    }),
  }),
});

export const { usePublicSitesQuery, useGetSiteHourlyPricesQuery } = extendedApi;

export const sitesApi = extendedApi;
