import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Card } from 'components/atoms/Card';
import { getEvseStatus } from 'handlers/device/device.handler';
import { SiteHandler } from 'handlers/site.handler';
import { useCurrency } from 'hooks/use-currency';
import { ConnectorType } from 'models/device.enums';
import { Evse } from 'models/evse';
import { Site } from 'models/site';
import { trackAmplitudeEvent } from 'services/amplitude';
import { AMPLITUDE_EVENTS, getAmplitudeEventPropertiesForEvse } from 'services/amplitude/data';
import { selectUser } from 'services/auth/selectors';
import { selectActiveReservation } from 'services/parkingAndReservations/selectors';
import { selectActiveTransaction } from 'services/transactions/selectors';

import { ConnectorIcon } from '../PublicSites/ConnectorIcon';
import { useComputedStatusDef } from './useComputedStatusDef';

type SinglePublicChargerProps = {
  evse: Evse;
  selectActiveEvse: (evse: Evse) => void;
  site: Site;
};

export const SinglePublicCharger = ({ evse, selectActiveEvse, site }: SinglePublicChargerProps) => {
  const { t } = useTranslation();
  const currency = useCurrency(site.defaultCurrency);
  const user = useSelector(selectUser);
  // TODO: Same logic problems as elsewhere already
  const activeTransaction = useSelector(selectActiveTransaction);
  const activeReservation = useSelector(selectActiveReservation);

  const siteHandler = new SiteHandler(site);
  const fixedPublicPrice = Math.ceil(Number(siteHandler.getPublicFixedPrice()) * 100);

  const status = getEvseStatus(evse, activeTransaction, activeReservation);
  const computedStatusDef = useComputedStatusDef(status, evse.maxPowerKw);

  const deviceFeatures = [
    {
      title: t('pricing', 'Pricing'),
      text: siteHandler.isMarketPrice() ? t('market', 'Market') : t('fixed', 'Fixed'),
      disabled: !siteHandler.isMarketPrice(),
    },
    {
      title: siteHandler.isMarketPrice() ? t('upcharge', 'Upcharge') : t('price', 'Price'),
      text: `${fixedPublicPrice} ${currency.centsName}/kWh`,
      disabled: siteHandler.isMarketPrice(),
    },
    {
      title: t('power', 'Power'),
      text: `${evse.maxPowerKw}kW`,
    },
    {
      title: t('type', 'Type'),
      text: ConnectorType[evse.connectorType as keyof typeof ConnectorType],
      icon: <ConnectorIcon type={evse.connectorType} />,
    },
  ];

  const handleSelectActiveEvse = () => {
    selectActiveEvse(evse);
    trackAmplitudeEvent(
      AMPLITUDE_EVENTS.SELECT_CHARGER,
      getAmplitudeEventPropertiesForEvse({ evse, site, user }),
    );
  };

  return (
    <div
      role="button"
      className="w-full border-none"
      onKeyDown={() => {}}
      onClick={handleSelectActiveEvse}
      tabIndex={0}
    >
      <Card
        full
        className={`
          bg-vool-gray-100
          ${!siteHandler.isMarketPrice() ? 'xs:max-h-120px' : ''}
        `}
        radiusClass="rounded-2xl"
        borderless
      >
        <div className="flex items-center space-x-4 p-4 w-full">
          {computedStatusDef.icon}
          <div className="flex flex-col w-full min-w-0">
            <div className="flex justify-between items-start w-full">
              <div className="flex flex-col w-full">
                <span className="text-base font-semibold truncate w-full">
                  {evse.name ?? evse.serialNumber ?? evse.deviceSerialNumber}
                </span>
                <span className="text-sm font-normal">{computedStatusDef.label}</span>
              </div>
            </div>
            <div className="flex flex-wrap items-start justify-between w-full">
              {deviceFeatures.map(
                (feature) =>
                  !feature.disabled && (
                    <div
                      key={feature.title}
                      className={`flex flex-col items-start justify-center font-normal ${
                        siteHandler.isMarketPrice() ? 'w-1/2 xs:w-auto' : ''
                      }`}
                    >
                      <span className="text-xs text-vool-gray-400">{feature.title}</span>
                      <span className="flex text-sm font-normal">
                        {feature.text}
                        {feature.icon}
                      </span>
                    </div>
                  ),
              )}
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
