import { Drawer, useMediaQuery } from '@mui/material';

import { Site } from 'models/site';

import { SiteCharagersList } from './SiteCharagersList';
import { SiteDrawerHeader } from './SiteDrawerHeader';
import { SlideBarEmptyView } from './SlideBarEmptyView';

type SiteDrawerProps = {
  isOpen: boolean;
  closeSlidebar: () => void;
  site: Site;
};

export const SiteDrawer = ({ isOpen, closeSlidebar, site }: SiteDrawerProps) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleCloseSlidebar = () => {
    closeSlidebar();
  };

  return (
    <Drawer
      variant="permanent"
      anchor={isMobile ? 'top' : 'right'}
      open={isOpen}
      PaperProps={{
        sx: {
          height: '100%',
          width: { xs: '100%', md: '408px' },
          border: 'none',
        },
      }}
    >
      <SiteDrawerHeader site={site} onCloseClicked={handleCloseSlidebar} />
      <div className="flex-1 px-4 pb-6 sm:px-6">
        {site.devices.length ? <SiteCharagersList site={site} /> : <SlideBarEmptyView />}
      </div>
      {/* TODO: This button is not behaving 100% accurate and positioning is messed up */}
      {/* {!!user && (
        <SiteNavigateButton
          parsedAddressJson={parsedAddressJson}
          onNavigateClicked={handleSelectSiteAsDestination}
        />
      )} */}
    </Drawer>
  );
};
